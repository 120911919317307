// register the plugin on vue
import ABI from '@/assets/abi/usdt.js'
import { BscUSDTAddress, BscRpcURL } from '@/assets/contract.js'
const Contract = require('web3-eth-contract');
Contract.setProvider(BscRpcURL);

export default class bscUsdt {
  constructor() {
    this.contract = new Contract(ABI, BscUSDTAddress);
    // console.log('this.contract', this.contract)
  }

  async getBalance(walletAddress) {
    try{
      let balance = await this.contract.methods.balanceOf(walletAddress).call();
      return parseFloat((parseInt(balance) / (10 ** 18)).toFixed(5));
    }catch(error){
      console.log('error', error)
    }
  }

  async transfer(toAddress, amount){
    amount = amount * (10 ** 18)
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData =  await this.contract.methods.transfer(toAddress, amountString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: BscUSDTAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}